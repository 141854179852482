@tailwind base;
@tailwind components;
@tailwind utilities;


/* 'paleta': {
const colors = {
  primary: '#26cfb1',
  complementary: '#b12683',
  secondary1: '#2684cf',
  secondary2: '#cf2626',
  secundary3: ´#E0A768,
  secundary4: ´#14CCAA,
  secundary5: ´#169292,
  secundary6: ´#E6EBF1,
  secundary7: ´#010101,

};

  '50': '#f2fdfa',
  '100': '#cafcef',
  '200': '#96f8df',
  '300': '#5aedcd',
  '400': '#26cfb0',
  '500': '#10bc9f',
  '600': '#0a9984',
  '700': '#0d7769',
  '800': '#0f6157',
  '900': '#124f48',
  '950': '#03302d',
}, 

Sombra
style={{ boxShadow: '0px 0px 122px 12px rgba(45,255,196,0.81)' }}

*/

body {
  background-image: url('../public/Background_Moon.svg');
  background-size: fill;
  background-repeat: repeat;
}